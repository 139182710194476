import React from "react";
import axios from "axios";
import { InboxOutlined } from "@ant-design/icons";
import { Button, message, Select } from "antd";
import { FormFileList } from "./FormFileList";

const endPoint =
  process.env.REACT_APP_API_URL || new URL(window.location.href).origin;

const Form = ({ table }) => {
  // a local state to store the currently selected file.
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [brand, setBrand] = React.useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (table === "accounts" && !brand) {
      message.error({ content: "Hesap Türü Seçilmedi", key: "message" });
      return;
    }

    message.loading({ content: "Veri Yükleniyor...", key: "message" });
    const formData = new FormData();
    formData.append("file", selectedFiles[0]);
    if (table === "accounts") {
      formData.append("brand", brand);
    }
    try {
      await axios({
        method: "post",
        url: `${endPoint}/api/${table}/create`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      setSelectedFiles([]);
      message.success({ content: "Veri Yüklendi", key: "message" });
    } catch (error) {
      // message.error({
      //   content:
      //     error.response.data.message === "No file uploaded"
      //       ? "Dosya Seçilmedi"
      //       : error.response.data.message,
      //   key: "message",
      // });
      console.log(error);
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFiles([event.target.files[0]]);
  };

  return (
    <div className="w-1/2 bg-white p-4">
      <form onSubmit={handleSubmit}>
        {table === "accounts" && (
          <Select
            placeholder="Hesap Türü"
            className="w-full mb-4"
            options={[
              { label: "Karcher", value: "KARCHER" },
              { label: "Tefal", value: "TEFAL" },
            ]}
            onChange={(value) => setBrand(value)}
          />
        )}
        <input
          type="file"
          id="input-file-upload"
          multiple={false}
          onChange={handleFileSelect}
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
        />
        <label id="label-file-upload" htmlFor="input-file-upload">
          <div className="flex flex-col items-center space-y-4">
            <InboxOutlined className=" text-5xl text-blue-300" />
            <p>Dosyayı yüklemek için tıklayınız.</p>
          </div>
        </label>

        <FormFileList fileList={selectedFiles} setFileList={setSelectedFiles} />

        <Button type="primary" htmlType="submit" className="bg-blue-400" block>
          Yükle
        </Button>
      </form>
    </div>
  );
};

export default Form;
