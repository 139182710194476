import React from "react";
import DataTable from "../DataTable";
import moment from "moment";
import { useAuth } from "../../contexts/AuthContext";
import { formatPrice } from "../../utils";

const CustomerTable = () => {
  const { user } = useAuth();

  const columns = [
    {
      title: "Kod",
      dataIndex: "code",
      key: "code",
      dataType: "string",
    },
    {
      title: "Marka",
      dataIndex: "brand",
      key: "brand",
      dataType: "enum",
      options: [
        user?.brands?.includes("Tefal") && {
          label: "Tefal",
          value: "TEFAL",
        },
        user?.brands?.includes("Karcher") && {
          label: "Karcher",
          value: "KARCHER",
        },
      ],
    },
    {
      title: "Hesap Adı",
      dataIndex: "name",
      key: "name",
      dataType: "string",
    },
    {
      title: "Bakiye",
      dataIndex: "balance",
      key: "balance",
      dataType: "float",
      render: (text, record) => {
        return formatPrice(text) + " ₺";
      },
    },
    {
      title: "Vade Tarih",
      dataIndex: "vadeDate",
      key: "vadeDate",
      render: (text, record) => {
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          return date.toLocaleDateString("tr-TR");
        };
        return formatDate(text);
      },
    },
    {
      title: "Vade Gün",
      dataIndex: "vadeDay",
      key: "vadeDay",
      dataType: "float",
    },
    {
      title: `${moment().format("DD.MM.YYYY")} için vade`,
      key: "vadeForToday",
      render: (text, record) => {
        const vadeDate = new Date(record.vadeDate);
        const today = new Date();
        const diffTime = vadeDate - today;
        // dont count today and last day
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
      },
    },
  ];

  return <DataTable columns={columns} table="accounts" />;
};
export default CustomerTable;
