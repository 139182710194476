import React from "react";
import AccountTable from "../tables/AccountTable";
import BreadCrumb from "../BreadCrumb";

const PageAccounts = () => (
  <>
    <BreadCrumb
      breadcrumb={[
        { title: "App" },
        { title: "Açık Hesaplar", href: "/accounts" },
      ]}
    />
    <AccountTable />
  </>
);

export default PageAccounts;
