import React from "react";
import BreadCrumb from "../BreadCrumb";
import ImportForm from "../ImportForm";

export const PageAccountsImport = () => {
  return (
    <>
      <BreadCrumb
        breadcrumb={[
          { title: "App" },
          { title: "Açık Hesaplar", href: "/accounts" },
          { title: "Açık Hesapları İçe Aktar" },
        ]}
      />
      <ImportForm table="accounts" />
    </>
  );
};
